import React from 'react';
import { graphql } from 'gatsby';

import PageHero from '../components/page-hero';
import Container from '../components/container';
import Column from '../components/column';
import Cta from '../components/cta';
import Heading from '../components/heading';
import Layout from '../components/layout';
import Row from '../components/row';
import { Section } from '../components/section';
import { Iframe, IframeContainer } from '../components/iframe';

const Moto = ({ data: { heroImg } }) => (
  <Layout>
    <PageHero img={heroImg} videoId="436159625">
      <Heading as="h1">Motors</Heading>
      <Cta to="#show-more">show more</Cta>
    </PageHero>
    <Section id="show-more">
      <Container>
        <Row>
          <Column offset="2">
            <Heading as="h2">Motors at Polish Boogie Festival</Heading>
          </Column>
        </Row>
        <Row>
          <Column offset="2">
            <p>
              The term café racer was born in the early 1960s by British motorcycle enthusiasts from Watford and London. They were called Rockers or Ton-up boys (from the term "ton" meaning 100 miles per hour). They used motorbikes for short rides between bars such as Watford Busy Bee café and Ace café in London. In post-war Britain, many people could not afford a car and for many, the motorcycle, often with a sidecar, was a family vehicle. Café racers, on the other hand, symbolized speed, status and rebellion, not the impossibility of owning a car.
            </p>
          </Column>
        </Row>
        <Row>
          <Column offset="2">
            <Heading as="h2">Café Racer Sprint</Heading>
          </Column>
        </Row>
        <Row>
          <Column offset="2">
            <p>
              To celebrate this old tradition, the Polish Boogie Festival together with Café Racer Club Poland organize Sprint, which is a training measured in pairs over a distance of ¼ kilometer, taking place in the street conditions. The currently known formula of Drag Race races, on specially prepared tracks, had its origins in the early 1950s, and its genesis is derived from street duels, local boys waiting only for the most beautiful local ladies to wave their scarf.

            </p>
            <p>
              The fun that takes place every year as part of our festival is a kind of reflection of the rivalry from those years. In the vapors of the absolute madness of music, clothes and the whole climate, motorcycles, taken straight from the era of the cultural revolution, take part in duels. Café racers, scramblers, choppers and all kinds of custom motorcycles and their drivers, encouraged by the audience, give their best to reach the finish line first. Often it is not the victory in the entire competition that counts, but the one current opponent ..
            </p>
          </Column>
        </Row>
        <Row justify="center">
          <IframeContainer>
            <Iframe title="vimeo-player" src="https://player.vimeo.com/video/436159625" frameBorder="0" allowFullScreen />
          </IframeContainer>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default Moto;

export const MotoryzacjaQuery = graphql`
  query MotoryzacjaQuery {
    heroImg: file(relativePath: { eq: "motoryzacja.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`;
